.google-reviews-widget {
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    color: #3C4043;
  }
  
  .review-card {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .review-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .review-author {
    font-weight: bold;
    font-size: 1.1em;
    color: #1A73E8;
  }
  
  .review-rating .star {
    color: #FFD700;
    font-size: 1.2em;
    margin-right: 2px;
  }
  
  .review-text {
    margin: 10px 0;
    font-size: 0.95em;
    line-height: 1.4;
  }
  
  .review-date {
    font-size: 0.85em;
    color: #5F6368;
  }
  