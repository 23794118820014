/* NavBar.css */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px;
  }
  
  .nav-menu {
    list-style-type: none;
    display: flex;
    gap: 10px;
  }
  
  .nav-menu.active {
    display: flex;
    flex-direction: column;
  }
  
  .nav-menu li {
    padding: 5px;
  }
  
  .nav-menu a {
    text-decoration: none;
    color: #010000;
  }
  
  .nav-menu a:hover {
    color: #090808;
  }
  
  .burger {
    display: none;
    cursor: pointer;
  }
  
  .line {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px;
    transition: transform 0.3s ease;
  }
  
  .open.line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .open.line2 {
    opacity: 0;
  }
  
  .open.line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .nav-menu {
      display: none;
    }
  
    .burger {
      display: block;
    }
  }
