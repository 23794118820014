/* Base font size */
html {
  font-size: 16px; /* 1rem = 16px */
}

body {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif,'Arial', sans-serif;
  line-height: 1.6; /* Adjust as needed for readability */
}

/* Headings */
h1 {
  font-size: 3rem; /* 48px */
  font-family: 'Palatino', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, Rockwell, Haettenschweiler, 'Arial Narrow Bold', sans-serif, copperplate, sans-serif;
  color: #0d0909; /* Adjust color as needed */
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2.25rem; /* 36px */
  font-family: 'Palatino', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, Rockwell, Haettenschweiler, 'Arial Narrow Bold', sans-serif, copperplate, sans-serif;
  color: #090404; /* Adjust color as needed */
  margin-bottom: 0.5em;
}

h3 {
  font-size: 1.5rem; /* 24px */
  font-family: 'Palatino', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, Rockwell, Haettenschweiler, 'Arial Narrow Bold', sans-serif, copperplate, sans-serif;
  color: #090404; /* Adjust color as needed */
  margin-bottom: 0.5em;
}

h4 {
  font-size: 1.5rem; /* 24px */
  font-family: 'Palatino', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, Rockwell, Haettenschweiler, 'Arial Narrow Bold', sans-serif, copperplate, sans-serif;
  /* font-style: italic; */
  color: #090404; /* Adjust color as needed */
  margin-bottom: 0.5em;
}

/* Paragraphs */
p {
  font-size: 1rem; /* 16px */
  color: #050303; /* Adjust color as needed */
  margin-bottom: 1em;
}

/* Buttons */
button {
  background: #007bff; /* Blue background */
  color: #ffffff; /* White text color for better contrast */
  border: none;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  font-size: 1rem; /* Adjust font size as needed */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  margin-bottom: 1em;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

/* Enhanced Buttons */


/* Hover effects */
button:hover {
  background: linear-gradient(135deg, #0ca306, #1dfe30); /* Reverse gradient on hover */
  transform: scale(1.05); /* Slight scaling effect on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}
/* Images */
img {
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin-top: 20px; /* Adjust as needed */
}

/* Centered content */
.centered {
  text-align: center;
  margin-top: 50px; /* Adjust as needed */
}

.centered .line-break {
  display: block;
}